<template>
  <div class="align-center justify-center py-7 h-full ghd-base-color-black ghd-text-white">
    <v-col
      lg="8"
      md="8"
      sm="12"
      offset-lg="2"
      offset-md="2"
    >
      <div
        class="text-center"
        style="margin: 0 auto"
      >
        <img
          height="auto"
          width="200"
          src="/images/logo-oro.png"
          class="mb-16"
        />
      </div>

      <div
        class="text-center pb-5"
        style="margin: 0 auto"
      >
        <strong>INFORMAZIONI AI SENSI DELL’ART. 13 DEL REG. UE N. 679/2016 (“GDPR”)</strong>
      </div>

      Questa Informativa descrive quali dei tuoi dati personali sono raccolti da ghd Italia S.r.l. per quali
      finalità e con quali modalità sono trattati. Di seguito troverai anche le informazioni necessarie per
      esercitare i tuoi diritti previsti dal GDPR.
      In generale, raccogliamo tuoi dati quando crei un account sul sito https://support.ghditalia.it, quando ci contatti per ricevere assistenza tramite il Portale.
      Ti informiamo che i dati personali raccolti durante tali processi saranno trattati al fine di rispondere alle tue
      richieste, di consentirti di fruire dei servizi offerti dal Portale per mezzo dell’accesso alle aree,
      secondo quanto indicato di seguito.
      <ul>
        <li>
          Chi tratta i tuoi dati: titolare del trattamento
          ghd Italia S.r.l., C.F. e P.IVA 05457920485, con sede legale in Firenze, via XX Settembre 98/100
          privacy@ghdhair.it è titolare del trattamento dei tuoi dati.
        </li>
        <li>
          Quali dati trattiamo - Tipologia di dati trattati
          I tuoi dati di contatto e account. Conserveremo le informazioni di contatto che ci fornisci (ad
          esempio, nome, cognome, indirizzo, numero di telefono, e-mail, nazione di residenza, data e luogo
          di nascita, codice fiscale, genere) quando crei un account sul Portale e quindi ci contatti per ricevere
          assistenza.
        </li>
        <li>
          Dove prendiamo i tuoi dati - Modalità di raccolta dei dati:
          Direttamente da te. Ad esempio, se crei un account, ci richiedi assistenza. Se non ci comunichi i
          tuoi dati non potrai registrarti sul Portale e non potrai, dunque, formulare le richieste di assistenza.
        </li>
        <li>
          Perché e per quanto tempo trattiamo i tuoi dati - Finalità e base giuridica del
          trattamento dei dati; periodo di conservazione
          <ul style="list-style-type: lower-alpha">
            <li>
              Per consentirti di creare un account sul Portale. Tratteremo i tuoi dati personali per consentirti
              di creare un account sul Portale, sia che tu effettui o meno una richiesta di assistenza.
              La base giuridica di questo trattamento è l’esecuzione di una richiesta contrattuale/precontrattuale
              da parte tua.
              Il periodo di conservazione dei tuoi dati, ulteriore rispetto a quello necessario per evadere il tuo
              eventuale ordine, sarà pari al periodo di attività del tuo account, che in ogni caso sarà disattivato
              decorsi 24 mesi dal tuo ultimo accesso o dalla tua ultima azione sull’account.
            </li>
            <li>
              Per fornirti la necessaria assistenza post-vendita in adempimento alla normativa
              applicabile alla garanzia legale dei prodotti. Per esempio, useremo i tuoi dati per fornirti
              assistenza, per gestire il reso dei prodotti da te acquistati, conformemente alla normativa
              applicabile.
              La base giuridica di questo trattamento è l’ottemperanza agli obblighi di legge ed il periodo di
              conservazione è pari al tempo necessario per espletare gli obblighi richiesti dalla normativa
              applicabile.
            </li>
            <li>
              Per fornirti assistenza post-vendita in adempimento alla garanzia convenzionale dei
              prodotti (ove esistente). Per esempio, useremo i tuoi dati per fornirti assistenza, conformemente
              a quanto previsto nelle condizioni di garanzia convenzionale riportate nel libretto di istruzioni dei
              prodotti.
              La base giuridica di questo trattamento è l’adempimento di obblighi contrattuali ed il periodo di
              conservazione è pari al tempo necessario per espletare gli obblighi derivanti dalla garanzia
              convenzionale.
            </li>
            <li>
              Per gestire correttamente la tua posizione amministrativa. Trattiamo i tuoi dati a fini contabili,
              amministrativi e fiscali, direttamente connessi alla nostra attività di impresa e richiesti dalle norme
              di legge applicabili.
              La base giuridica di questo trattamento è l’ottemperanza agli obblighi di legge ed il periodo di
              conservazione è pari a quello richiesto dalla normativa (in particolare quella fiscale, antiriciclaggio,
              bancaria e di pubblica sicurezza).
            </li>
            <li>
              Per prevenire o controllare condotte illecite o per proteggere e far valere diritti. Per esempio,
              potremmo usare i tuoi dati per prevenire la violazione di nostri diritti di proprietà intellettuale o altri
              illeciti, secondo quanto concesso dalla normativa applicabile.
              La base giuridica di tale trattamento è il legittimo interesse del Titolare. Il periodo di conservazione
              dei tuoi dati è pari al tempo ragionevolmente necessario a far valere i nostri diritti dal momento in
              cui veniamo a conoscenza dell’illecito o della sua potenziale commissione.
            </li>
          </ul>
        </li>
        <li>
          Natura del conferimento dei dati personali
          Per le finalità da a) ad e) di cui al paragrafo precedente, il conferimento dei dati è necessario a
          consentirti di creare un account sul Portale e richiedere l’assistenza in relazione ai prodotti
          acquistati.
        </li>
        <li>
          Dove sono trattati i tuoi dati – trasferimento dei dati
          I dati saranno trattati e conservati presso gli uffici di uffici di ghd, nonché presso fornitori operanti
          nell’Unione Europea e – qualora siano soddisfatte le garanzie di tutela previste dagli artt. 45-47 del
          GDPR - al di fuori dell’Unione Europea.
        </li>
        <li>
          Con chi condividiamo i tuoi dati – destinatari dei dati personali
          Fermo restando che, laddove richiesto dalla legge, otterremo il tuo preventivo consenso ed
          espleteremo eventuali formalità richieste dalla legge stessa, potremo condividere i tuoi dati con i
          seguenti soggetti terzi (anche in qualità di responsabili del trattamento).
          Nostri fornitori di servizi Potremmo condividere i tuoi dati con soggetti terzi in modo che gli stessi
          possano prestarci dei servizi, ma in tal caso concluderemo un accordo conforme all’art. 28 GDPR
          volto a tutelare i tuoi dati. Questi soggetti entrano in possesso solo dei dati necessari per
          l’espletamento delle loro funzioni e possono utilizzarli solamente al fine di eseguire tali servizi per
          nostro conto o per adempiere a norme di legge. Puoi conoscere i dettagli di tali responsabili del
          trattamento ai sensi dell’art. 28 GDPR contattandoci via email all’indirizzo sopra indicato. Qualora
          tali fornitori operassero al di fuori dell’Unione Europea, prima di comunicare loro qualsiasi dato
          personale soddisferemo una delle condizioni previste dagli artt. 45-47 del GDPR.
          Laddove ritenessimo di doverlo fare per ottemperare a obblighi di legge o al fine di tutelare
          giudizialmente noi, o terze parti. Laddove permesso o richiesto dalla legge, possiamo anche
          condividere i dati richiesti da un ente governativo o da altro soggetto o organizzazione autorizzato
          al fine di proteggere o esercitare i diritti nostri o di terze parti, o al fine di limitare o evitare altri illeciti.
          Da ultimo potremmo condividere i tuoi dati con società del gruppo al quale appartiene ghd in forza
          del considerando (48) del GDPR.
        </li>
        <li>
          Minori Il nostro Portale non è destinato ai minori di 18 anni, ma è destinato ad un pubblico adulto.
          Se sei un genitore o chi ne fa le veci e pensi che tuo figlio ci abbia trasmesso dei dati, puoi contattarci.
        </li>
        <li>
          Misure di sicurezza
          Adottiamo le misure di sicurezza richieste dalla legge.
          Adottiamo misure di sicurezza per proteggere i tuoi dati. Le misure di sicurezza standard che
          utilizziamo dipendono dalla tipologia di dati che trattiamo e rispondono ai requisiti previsti dalla
          legge e dagli standard di agenzie governative europee.
        </li>
        <li>
          I tuoi diritti
          Puoi rivolgerti a ghd per chiedere l’accesso ai tuoi dati personali, la rettifica, la cancellazione degli
          stessi o la limitazione del trattamento, per opporti al trattamento e chiedere la portabilità̀dei tuoi
          dati; puoi altresì revocare il consenso in qualsiasi momento (ciò non pregiudicherà la liceità del
          trattamento basata sul consenso prestato prima della revoca).
          Quando eserciti il diritto di accesso, hai diritto di sapere se è in corso il trattamento dei tuoi dati,
          quale sia la finalità del trattamento, quali sono le categorie di dati trattati, chi sono i destinatari o le
          categorie di destinatari a cui sono comunicati i tuoi dati (e, se risiedono in un paese terzo, sulla
          base di quali garanzie), il periodo di conservazione dei tuoi dati (o i criteri per determinare il periodo
          di conservazione), se sia in corso un trattamento automatizzato (per esempio mediante
          profilazione) e quale sia la logica di tale trattamento, l’origine dei dati (qualora non siano raccolti
          inizialmente da noi).
          Hai diritto di proporre reclamo alla compente autorità di controllo e di chiedere al Titolare, in
          qualsiasi momento, informazioni circa i responsabili del trattamento e i soggetti autorizzati dal
          Titolare a trattare i tuoi dati.
          Puoi esercitare i tuoi diritti rivolgendoti a ghd ai recapiti sopra indicati.
        </li>
        <li>
          Cosa facciamo se modifichiamo questa informativa
          Potremmo apportare delle modifiche alla nostra privacy policy. Pubblicheremo anche una versione
          aggiornata sul Portale. Avrà una data diversa da quella indicata sotto. Sei pregato di consultare il
          Portale periodicamente per gli aggiornamenti.
        </li>
      </ul>
      Versione aggiornata al 16/03/2022
    </v-col>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
}
</script>

<style scoped>

</style>
